<template>
  <div class="optionMenu" :style="{height: height + 'px'}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name:'OptionMenu',
  props:{
    height:{
      type:Number,
      default:40
    },
  },
  data() {
    return {

    }
  },
  methods:{
  },
  mounted(){

  }
}
</script>

<style scoped>
.optionMenu{
  cursor: pointer;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #EEEFF4;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
}
</style>

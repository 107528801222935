<template>
  <div class="drowpDownMenuBox" :style="{ height: height + 'px',width: width+'px',background: color }">
    <div class="tittleBox">
      {{title}}
    </div>
  </div>

  <div class="menu" :style="{ width: width+'px',background: color }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name:'DrowpDownMenu',
  props:{
    title:null,
    width:{
      type:Number,
      default: 240
    },
    height:{
      type:Number,
      default: 40
    },
    color:{
      type:String,
      default: "#EEEFF4"
    }
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>
.drowpDownMenuBox{
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
  transition: .2s;
  background: #EEEFF4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.drowpDownMenuBox:hover~.menu{
  display: block;
}
.menu:hover.menu{
  display: block;
}
.tittleBox{
  width: 100%;
  text-align: center;
  line-height: 100%;
}
.tittleName{
  text-align: center;
  font-family: Epilogue;
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
}
.menu{
  position: absolute;
  display: none;
  width: 100%;
  height: auto;
  max-height: 260px;
  overflow: hidden;
  overflow-y:auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
  z-index: 9;
}
.menu::-webkit-scrollbar{
  /*滾動條寬度*/
  width: 3px;
}
.menu::-webkit-scrollbar-thumb {
  /*滾動條顏色*/
  background-color: #7e7e7e;
}

</style>
